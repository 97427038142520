<template>
  <div class="container header__container" v-if="header">
    <router-link to="/" class="header__logo" v-if="header.logo">
      <v-lazy-image
        :src="header.logo[0]['url']"
        rel="preload"
        width="38"
        height="38"
        alt="logo"
      />
    </router-link>
    <nav class="header__menu">
      <p class="header__menu--text" v-if="translations[$i18n.locale]">
        {{ getField("company", $i18n.locale) }}

        <a href="https://iitt.kz/" target="_blank">{{
          $t("OUR REPRESENTATION")
        }}</a>
      </p>
      <router-link to="/" class="header__menu--home">
        <img
          src="@/assets/images/home.svg"
          rel="preload"
          width="17"
          height="16"
          alt="home"
        />
      </router-link>
      <!--Services menu-->
      <ul class="header__menu--list menu--list" v-if="services && menu">
        <li
          class="menu--list__item"
          v-for="nesting_0 in services"
          :key="nesting_0.id"
        >
          <router-link
            class="menu--list__link"
            :class="{ hover: nesting_0.children.length > 0 }"
            :to="'/' + nesting_0.slug"
            >{{
              getTranslatedName(nesting_0.translations, $i18n.locale)
            }}</router-link
          >
          <ul
            class="menu--list__dropdown dropdown--list"
            v-if="nesting_0.children.length > 0"
          >
            <li
              class="dropdown--list__item"
              v-for="nesting_1 in nesting_0.children"
              :key="nesting_1.id"
            >
              <router-link
                v-if="nesting_0.slug === 'komplektuyuci'"
                class="dropdown--list__link"
                :to="'/' + nesting_0.slug + '/' + nesting_1.slug"
                >{{
                  getTranslatedName(nesting_1.translations, $i18n.locale)
                }}</router-link
              >
              <router-link
                v-else
                class="dropdown--list__link"
                :to="'/' + nesting_1.slug"
                >{{
                  getTranslatedName(nesting_1.translations, $i18n.locale)
                }}</router-link
              >
            </li>
          </ul>
        </li>
        <!--Pages menu-->
        <li
          class="menu--list__item"
          v-for="nesting_0 in menu"
          :key="nesting_0.id"
        >
          <router-link
            class="menu--list__link"
            :class="{ hover: nesting_0.children.length > 0 }"
            :to="nesting_0.url"
            >{{
              getTranslatedTitle(nesting_0.translations, $i18n.locale)
            }}</router-link
          >
          <ul
            class="menu--list__dropdown dropdown--list"
            v-if="nesting_0.children.length > 0"
          >
            <li
              class="dropdown--list__item"
              v-for="nesting_1 in nesting_0.children"
              :key="nesting_1.id"
            >
              <router-link class="dropdown--list__link" :to="nesting_1.url">{{
                getTranslatedTitle(nesting_1.translations, $i18n.locale)
              }}</router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <div class="header__search">
      <button class="header__search--btn" @click="$emit('showFormSearch')">
        search
      </button>
    </div>
    <div class="header__call">
      <div class="header__call--number">
        <a :href="'tel:' + header.tel">
          <span>{{ header.tel }}</span>
          <span>{{ t("telephone") }}</span>
        </a>
        <span v-if="translations[$i18n.locale]">{{
          getField("country", $i18n.locale)
        }}</span>
      </div>

      <a
        href="#callBack"
        class="header__call--back"
        @click="modalToggle"
        :class="{ openPopup: showModal }"
        >{{ t("call_back") }}</a
      >
      <transition name="popup">
        <Popup v-if="showModal" @click="showModal = false" />
      </transition>
    </div>
    <div class="header__social">
      <a class="header__social--link fb" :href="header.facebook" target="_blank"
        >facebook</a
      >
      <a class="header__social--link in" :href="header.linkedin" target="_blank"
        >linkedin</a
      >
    </div>
    <lang-switcher></lang-switcher>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Popup = defineAsyncComponent(() => import("@/components/Popup.vue"));
const LangSwitcher = defineAsyncComponent(() =>
  import("@/components/UI/LangSwitcher")
);
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VLazyImage from "v-lazy-image";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    Popup,
    VLazyImage,
    LangSwitcher,
  },
  props: {
    header: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      showForm: false,
    };
  },
  methods: {
    modalToggle: function (e) {
      e.preventDefault();
      this.showModal = !this.showModal;
    },
    getTranslations(locale) {
      return this.translations[locale];
    },
    getField(fieldName, locale) {
      return this.getTranslations(locale)[fieldName];
    },
    getTranslatedTitle(transArray, locale) {
      let title = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          title = element.title;
        }
      });
      return title;
    },
    getTranslatedName(transArray, locale) {
      let name = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          name = element.name;
        }
      });
      return name;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";
.header {
  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 72px;
    height: 72px;
    flex: 0 0 auto;
    @include property("margin-right", 55, 15, true, 1670, 1366);

    img {
      display: block;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 auto;

    &--text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 25px;
      font-size: 1rem;
      line-height: calc(25 / 16);
      letter-spacing: 3.2px;
      color: #9d772b;
      margin-bottom: 0;
      opacity: 1;
      transition: all 0.3s linear;

      .scrolling & {
        height: 0;
        opacity: 0;
        visibility: hidden;
      }

      a {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-size: 10px;
        letter-spacing: 0.5px;
        margin-left: 50px;
        color: #afafaf;
        text-transform: uppercase;
        text-decoration: none !important;
        padding-left: 37px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 28px;
          height: 100%;
          background: url("~@/assets/images/kazakhstan.jpg") center center /
            100% 100% no-repeat;
        }
      }
    }

    &--home {
      width: 17px;
      height: 16px;
      margin-top: 9px;
      margin-bottom: 13px;

      img {
        display: block;
      }
    }
  }

  &__search {
    display: flex;
    padding: 23px;
    @include property("padding-right", 30, 10, true, 1670, 1280);
    @include property("padding-left", 30, 10, true, 1670, 1280);

    @media (hover: hover) {
      &:hover {
        .header__search--btn {
          &::before {
            width: 100%;
            opacity: 1;
            visibility: visible;
          }
        }

        .search--hide {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
        }
      }
    }

    &--btn {
      position: relative;
      font-size: 0;
      width: 30px;
      height: 30px;
      background: url("~@/assets/images/search.svg") center center no-repeat;
      background-size: 16px 16px;

      &::before {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 7px;
        width: 0;
        max-width: 16px;
        height: 3px;
        background-color: #f9b80e;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
      }
    }
  }

  &__call {
    min-width: max-content;

    @media (min-width: 1280px) and (max-width: 1510px) {
      display: flex;
    }

    &--number {
      display: flex;
      align-items: center;

      a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        line-height: calc(26 / 20);
        text-decoration: none !important;

        &::before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          flex: 0 0 auto;
          margin-right: 15px;
          background: url("~@/assets/images/phone-icon.svg") no-repeat;
          background-size: 100% 100%;

          @media (min-width: 1280px) and (max-width: 1510px) {
            width: 20px;
            height: 20px;
            margin: 0 15px 0 5px;
          }
        }

        @media (hover: hover) {
          &:hover {
            span {
              &:nth-of-type(1) {
                opacity: 0;
                visibility: hidden;
              }

              &:nth-of-type(2) {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        span {
          transition: all 0.3s linear;

          @media (min-width: 1280px) and (max-width: 1510px) {
            font-size: 0;
          }

          &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 31px;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            color: #f9b80e;
          }
        }
      }

      > span {
        font-size: 20px;
        line-height: calc(26 / 20);
        margin-left: 15px;

        @media (max-width: 1510px) {
          display: none;
        }
      }
    }

    &--back {
      position: relative;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #f9b80e;
      font-size: 20px;
      line-height: calc(26 / 20);
      color: #f9b80e;
      margin-top: 8px;

      @media (min-width: 1280px) and (max-width: 1510px) {
        margin-top: 0;
        font-size: 0;
        border: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        flex: 0 0 auto;
        margin-left: 10px;
        background-color: #f9b80e;
        mask-image: url("~@/assets/images/arrow_call.svg");
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        transition: background 0.3s linear, transform 0.3s linear;

        @media (min-width: 1280px) and (max-width: 1510px) {
          width: 24px;
          height: 24px;
          margin: 0 5px;
          background: url("~@/assets/images/arrow_call-2.svg") !important;
          mask-image: none;
          transform: rotate(0) !important;
        }
      }

      &.openPopup {
        text-decoration: none;
        color: #fff;
        border-color: #fff;

        &::after {
          background-color: #fff;
          transform: rotate(45deg);
        }
      }

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: #fff;
          border-color: #fff;

          &::after {
            background-color: #fff;
          }
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    @include property("margin-right", 32, 15, true, 1670, 1366);
    @include property("margin-left", 32, 15, true, 1670, 1366);

    &--link {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-color: #f9b80e;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;

      &:not(:last-child) {
        margin-right: 18px;
      }

      &.fb {
        mask-image: url("~@/assets/images/fb.svg");
      }

      &.in {
        mask-image: url("~@/assets/images/in.svg");
      }

      @media (hover: hover) {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  &__lang {
    display: flex;
    align-items: center;
    border: 1px solid #707070;
    padding: 5px 15px;

    &--link {
      text-decoration: underline;
      color: #f9b80e;

      &:nth-of-type(n + 2) {
        margin-left: 15px;
      }

      &.active {
        color: #929292;
        text-decoration: none !important;
        pointer-events: none;
        cursor: none;
      }

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.menu--list {
  display: flex;
  align-items: center;
  @include property("margin-left", 38, 15, true, 1670, 1366);

  &__item {
    @media (hover: hover) {
      &:hover {
        .dropdown--list {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          pointer-events: all;
        }

        .menu--list__link.hover {
          &::before {
            width: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    &:not(:last-child) {
      @include property("margin-right", 27, 18, true, 1670, 1366);
    }

    &:nth-of-type(-n + 2) {
      > .menu--list__link {
        font-size: 18px;
        line-height: calc(23 / 18);
        letter-spacing: 0.9px;
        color: #f9b80e;
        text-transform: uppercase;
      }
    }
  }

  &__link {
    cursor: pointer;
    font-size: 1rem;
    line-height: calc(20 / 16);
    color: #aaaaaa;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 3px solid transparent;
    text-decoration: none !important;

    &.hover {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #f9b80e;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s linear;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        top: 100%;
        left: 0;
      }

      @media (hover: hover) {
        &:hover {
          &::after {
            height: 30px;
          }
        }
      }
    }
  }
}

.dropdown--list {
  position: absolute;
  left: 0;
  top: calc(100% - 15px);
  right: 0;
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: space-between;
  gap: 15px;
  background: #000000;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-15px);
  @include property("padding-top", 80, 40, true, 1670, 1320);
  @include property("padding-bottom", 80, 40, true, 1670, 1320);
  @include property("padding-left", 285, 40, true, 1670, 1320);
  @include property("padding-right", 285, 40, true, 1670, 1320);
  pointer-events: none;
  transition: all 0.3s linear;

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: calc(23 / 18);
    letter-spacing: 0.9px;
    color: #f9b80e;

    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      flex: 0 0 auto;
      background: url("~@/assets/images/arrow_menu.svg") no-repeat;
      background-size: 100% 100%;
    }
  }
}

//// анимация появления
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
}

.popup-enter-active .popup-container,
.popup-leave-active .popup-container {
  opacity: 1;
}
</style>
